import { Component, Vue } from 'vue-property-decorator';
import { PhoneIcon, MailIcon, MapIcon, FacebookIcon } from 'vue-feather-icons';
import { isIOS, isAndroid } from 'mobile-device-detect';

@Component({
  components: {
    PhoneIcon,
    MailIcon,
    MapIcon,
    FacebookIcon,
  },
  directives: {},
})
export default class Footer extends Vue {
  public get FacebookLink(): string {
    if (isIOS) {
      return 'fb://profile/609804002398193';
    } else if (isAndroid) {
      return 'fb://page/609804002398193/';
    } else {
      return 'https://www.facebook.com/Dolce-Vita-609804002398193/';
    }
  }
}
