import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';

export interface NavigationState {
  expanded: boolean;
}

export const navigationState: NavigationState = {
  expanded: false,
};

const namespaced: boolean = true;

export const mutations: MutationTree<NavigationState> = {
  toogleExpanded(state, payload) {
    state.expanded = payload !== null ? payload : !state.expanded;
  },
};

export const actions: ActionTree<NavigationState, RootState> = {
  toogleExpanded(store) {
    this.commit('navigation/toogleExpanded', null);
  },
  disableExpanded(store) {
    this.commit('navigation/toogleExpanded', false);
  },
};

export const getters: GetterTree<NavigationState, RootState> = {
  isExpanded() {
    return navigationState.expanded;
  },
};

export const navigation: Module<NavigationState, RootState> = {
  namespaced,
  state: navigationState,
  actions,
  getters,
  mutations,
};
