import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home/Home.vue';
import About from '@/views/About/About.vue';
import Parties from '@/views/Parties/Parties.vue';
import Gallery from '@/views/Gallery/Gallery.vue';
import LocationContact from '@/views/LocationContact/LocationContact.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/about',
      component: About,
    },
    {
      path: '/parties',
      component: Parties,
    },
    {
      path: '/location-contact',
      component: LocationContact,
    },
    {
      path: '/gallery',
      component: Gallery,
    },
  ],
});
