import { Component, Vue } from 'vue-property-decorator';
import scrollDirective from '@/directives/scroll-directive';
import MusicWave from '@/components/MusicWave/MusicWave';
import { MenuIcon, FacebookIcon } from 'vue-feather-icons';
import { isIOS, isAndroid } from 'mobile-device-detect';

const namespace: string = 'navigation';

@Component({
  components: {
    MenuIcon,
    FacebookIcon,
    MusicWave,
  },
  directives: {
    scrollDirective,
  },
})
export default class Navigation extends Vue {
  get Expanded(): boolean {
    return this.$store.getters['navigation/isExpanded'];
  }

  get Language(): string {
    return this.$store.getters.language;
  }

  public get FacebookLink(): string {
    if (isIOS) {
      return 'fb://profile/609804002398193';
    } else if (isAndroid) {
      return 'fb://page/609804002398193/';
    } else {
      return 'https://www.facebook.com/Dolce-Vita-609804002398193/';
    }
  }

  public toogleMenu() {
    this.$store.dispatch('navigation/toogleExpanded');
  }

  public disableMenu() {
    this.$store.dispatch('navigation/disableExpanded');
  }

  public changeLanguage(lang: string) {
    this.$store.dispatch('changeLanguage', lang);
    this.$store.dispatch('navigation/disableExpanded');
    this.$i18n.locale = lang;
  }

  public handleScroll(event: Event, el: HTMLElement) {
    if (window.scrollY >= 150) {
      el.classList.add('bg');
    } else {
      el.classList.remove('bg');
    }
  }

  public mounted() {
    this.$router.afterEach((to, from) => {
      this.disableMenu();
      window.scrollTo(0, 0);
    });
  }
}
