import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({
  components: {},
})
export default class Contact extends Vue {

  public submitted = false;

  public name = {
    value: '',
    minlength: 3,
    maxlength: 150,
    valid: false,
  };
  public email = {
    value: '',
    valid: false,
  };
  public message = {
    text: '',
    minlength: 100,
    valid: false,
  };
  private emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  public mounted() {
    this.$watch('name.value', (value) => {
      if (
        !!value &&
        value.length >= this.name.minlength &&
        value.length <= this.name.maxlength
      ) {
        this.name.valid = true;
      } else {
        this.name.valid = false;
      }
    });

    this.$watch('email.value', (value) => {
      this.validate('email', value);
    });

    this.$watch('message.text', (value) => {
      if (!!value && value.length >= this.message.minlength) {
        this.message.valid = true;
      } else {
        this.message.valid = false;
      }
    });
  }

  public validate(type: string, value: any) {
    if (type === 'email') {
      this.email.valid = this.isEmail(value) ? true : false;
    }
  }

  public isEmail(value: string) {
    return this.emailRegExp.test(value);
  }

  public submitForm() {
    this.submitted = true;
    if (this.name.valid && this.email.valid && this.message.valid) {
      const bodyFormData = new FormData();
      bodyFormData.set('name', this.name.value);
      bodyFormData.set('email', this.email.value);
      bodyFormData.set('content', this.message.text);

      axios
        .post('http://www.klubdolcevita.com/contact/contact.php', bodyFormData)
        .then((response) => {
          alert('Wiadomość została wysłana');
        })
        .catch((response) => {
          alert(
            'Coś poszło nie tak. Skontaktuj się przez facebooka, bądź zadzwoń',
          );
        });
    }
  }
}
