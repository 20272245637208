import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';
import { RootState } from '@/store';

export interface HomeState {
  musicPlay: boolean;
}

export const homeState: HomeState = {
  musicPlay: false,
};

const namespaced: boolean = true;

export const mutations: MutationTree<HomeState> = {
  toogleMusic(state, payload) {
    const musicEl = document.getElementById('music') as HTMLAudioElement;

    state.musicPlay = !state.musicPlay;
    if (state.musicPlay) {
      if (!musicEl.hasAttribute('src')) {
        musicEl.setAttribute('src', musicEl.getAttribute('data-src') as string);
      }
      musicEl.play();
    } else {
      musicEl.pause();
    }
  },
};

export const actions: ActionTree<HomeState, RootState> = {
  toogleMusic(store) {
    this.commit('home/toogleMusic', {});
  },
};

export const getters: GetterTree<HomeState, RootState> = {
  isMusicPlayed() {
    return homeState.musicPlay;
  },
};

export const home: Module<HomeState, RootState> = {
  namespaced,
  state: homeState,
  actions,
  getters,
  mutations,
};
