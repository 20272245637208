import { Component, Vue } from 'vue-property-decorator';
import {
  PlayIcon,
  PauseIcon,
  PhoneIcon,
  MailIcon,
  MapIcon,
  ClockIcon,
  GiftIcon,
} from 'vue-feather-icons';
import Contact from '@/components/Contact/Contact.vue';
import { jarallax } from 'jarallax';

@Component({
  components: {
    PlayIcon,
    PauseIcon,
    PhoneIcon,
    MailIcon,
    MapIcon,
    ClockIcon,
    GiftIcon,
    Contact,
  },
})
export default class Home extends Vue {
  get isMusicPlayed(): string {
    return this.$store.getters['home/isMusicPlayed'];
  }

  public toogleMusic() {
    this.$store.dispatch('home/toogleMusic');
  }

  public mounted() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
    });
  }
}
