import { Component, Vue } from 'vue-property-decorator';
import { jarallax } from 'jarallax';

@Component({
  components: {},
  directives: {},
})
export default class About extends Vue {
  public mounted() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.2,
    });
  }
}
