import { Component, Vue } from 'vue-property-decorator';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import VueLazyload from 'vue-lazyload';

import Navigation from '@/components/Navigation/Navigation.vue';
import Footer from '@/components/Footer/Footer.vue';

@Component({
  components: {
    Navigation,
    Footer,
    VueCookieAcceptDecline,
  },
})
export default class App extends Vue {
  public cookies: any = 'cookies';

  public created() {
    Vue.use(VueLazyload);
  }

  public mounted() {
    this.$i18n.locale = this.$store.getters.language;
    this.$router.afterEach((hook) => {
      setTimeout(() => {
        FB.XFBML.parse();
      }, 100);
    });
  }
}
