import { DirectiveOptions } from 'vue';

const scrollDirective: DirectiveOptions = {
    inserted(el, node) {
        const f = (evt: Event) => {
          if (node.value(evt, el)) {
            window.removeEventListener('scroll', f);
          }
        };

        window.addEventListener('scroll', f);
    },
};

export default scrollDirective;
