import Vue from 'vue';
import Vuex, { StoreOptions, GetterTree, ActionTree, MutationTree } from 'vuex';
import { navigation } from '@/store/modules/navigation';
import { home } from '@/store/modules/home';

Vue.use(Vuex);

const LanguageSet = new Set();
LanguageSet.add('pl');
LanguageSet.add('en');

export type SupportedLanguages = 'pl' | 'en' | 'ua';

const initialLanguage = () => {
  const persistedLanguage = window.localStorage.getItem('language');
  const [ browserLanguage, browserRegional ] =
    (navigator.language || (navigator as any).userLanguage)
    .split('-');

  if (LanguageSet.has(persistedLanguage)) {
    return persistedLanguage;
  }
  if (LanguageSet.has(browserLanguage)) {
    return browserLanguage;
  }

  return 'pl';
};

export interface RootState {
  version: string;
  language: SupportedLanguages;
}

export const appState: RootState = {
  language: initialLanguage(),
  version: '0.0.2',
};

export const getters: GetterTree<RootState, RootState> = {
  language() {
      return appState.language;
  },
};

export const mutations: MutationTree<RootState> = {
  changeLanguage(state, payload: SupportedLanguages) {
    state.language = payload;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  changeLanguage(store, payload: SupportedLanguages) {
    this.commit('changeLanguage', payload);
    window.localStorage.setItem('language', payload);
  },
};

const appStore: StoreOptions<RootState> = {
  state: appState,
  getters,
  actions,
  mutations,
  modules: {
    navigation,
    home,
  },
};

export default new Vuex.Store<RootState>(appStore);
