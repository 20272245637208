import { Component, Vue } from 'vue-property-decorator';
import { CoffeeIcon } from 'vue-feather-icons';
import Contact from '@/components/Contact/Contact.vue';

@Component({
  components: {
    CoffeeIcon,
    Contact,
  },
})
export default class Parties extends Vue {}
