import { Component, Vue } from 'vue-property-decorator';
import { PhoneIcon, MailIcon, MapIcon } from 'vue-feather-icons';

import Contact from '@/components/Contact/Contact.vue';

@Component({
  components: {
    Contact,
    PhoneIcon,
    MailIcon,
    MapIcon,
  },
})
export default class LocationContact extends Vue {}
