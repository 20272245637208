import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  directives: {},
})
export default class MusicWave extends Vue {
  get isMusicPlayed(): string {
    return this.$store.getters['home/isMusicPlayed'];
  }

  public toogleMusic() {
    this.$store.dispatch('home/toogleMusic');
  }
}
