import VueGallery from 'vue-gallery';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    VueGallery,
  },
  directives: {},
})
export default class Gallery extends Vue {

  public static path: string = '/photos';
  public images: string[] = [];
  public index: number | null = null;

  public mounted() {
    this.images = [...Array(24).keys()].map(
      (i) => `${Gallery.path}/${i + 1}.jpg`,
    );
  }
}
